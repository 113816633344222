export const ROUTE_PATHS = {
  // 'Pet Profiles (WIP)': '/pet-profiles',
  // 'Photo Contest': '/photo-contest',
  'User Profiles': '/user-profiles',
  'Pet Medical Records': '/pet-medical-records'
};

export const API_URL = 'https://kintopia-api.dev.kinship.tv';

export const LINNAEUS='LINNAEUS';
