import { KinshipPetProfile, CustomField, PracticeGroup, PracticeLocation } from "../../utils/kinshipTypes";
import { getVeterinarySpecialists, getLinnaeusPracticeGroup, updatePetProfile } from "../../utils/back4AppApi";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { translate } from "../../utils/localisation";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  IconButton,
  Box,
  Typography,
  MenuItem,
  Select,
  Stack,
  Grid,
} from "@mui/material";

interface Props {
  customFields?: CustomField[];
  pet?: KinshipPetProfile;
  onAction?: Function;
}

export function PracticeLocations({ customFields, pet, onAction = () => {} }: Props) {
  const [selectedPracticeLocation, setSelectedPracticeLocation] = useState<PracticeLocation | undefined>();
  const [practiceLocation, setPracticeLocation] = useState<PracticeLocation[]>([]);
  const [newPracticeLocationID, setNewPracticeLocationID] = useState("");

  const onPracticeLocationAction = async (event: any, ...args: Array<string | null>) => {
    const actionType = args[0];
    const practiceLocationArg = args[1];
    const actionHasConfirmDialogStep = args[2];
    let data = {};

    if (actionHasConfirmDialogStep === "NEEDS_CONFIRMATION") {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(translate('are_you_sure_remove_practice_location'))) {
        onPracticeLocationAction(event, actionType, practiceLocationArg);
      }
    }
    else if(actionType === "SET") {
      if (!practiceLocationArg) {
        return;
      }

      if (!practiceLocation?.find((practiceLocationSelected) => practiceLocationSelected.kinshipId === practiceLocationArg)) {
        return;
      }

      data = {
        preferredLocation: practiceLocationArg
      }
    } else if (actionType === "CLEAR") {
      data = {
        preferredLocation: "NONE"
      }
    }

    await updatePetProfile(pet, data);

    onAction(data);
  };

  useEffect(() => {
    const fetchPracticeLocation = async () => {
      const partnerInfo = getLinnaeusPracticeGroup() as unknown as PracticeGroup;
      if (partnerInfo) {
        const data  = await getVeterinarySpecialists(
          partnerInfo?.partnerCode || '',
          partnerInfo?.kinshipId || ''
        );

        const practiceLocationData = data.data as Array<PracticeLocation>;

        setPracticeLocation(practiceLocationData);

        console.log("preferredLocation =>", pet?.preferredLocation);
        console.log("practiceLocationData =>", practiceLocationData);

        const alreadySelectedPracticeLocation = practiceLocationData?.find((practiceLocationSelected) => practiceLocationSelected.kinshipId === pet?.preferredLocation);

        console.log("alreadySelectedPracticeLocation =>", alreadySelectedPracticeLocation);

        setSelectedPracticeLocation(alreadySelectedPracticeLocation);
      }
    }

    fetchPracticeLocation();
  }, [customFields, newPracticeLocationID, pet?.preferredLocation]);

  const PracticeLocationRender = practiceLocation.map((menuItem) => {
    return (
      <MenuItem key={`${menuItem.email} - ${menuItem.kinshipId}`} value={menuItem.kinshipId}>
        {menuItem?.name || ""} - {menuItem?.phone || ""} - {menuItem?.email || ""}  - {menuItem?.address || ""}
      </MenuItem>
    );
  });

  const mappedPracticeLocation = () => {
    if(selectedPracticeLocation) {
      return (
        <TableRow>
          <TableCell align="left" colSpan={2}>
            {selectedPracticeLocation?.name || ""} - {selectedPracticeLocation?.phone || ""} - {selectedPracticeLocation?.email || ""}  - {selectedPracticeLocation?.address || ""}
          </TableCell>
          <TableCell align="left" colSpan={2}>
              <Stack direction="row" spacing={1}>
                <IconButton
                  color="primary"
                  aria-label={translate('delete')}
                  onClick={(event) => onPracticeLocationAction(event, "CLEAR", selectedPracticeLocation?.kinshipId || "", "NEEDS_CONFIRMATION")}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
          </TableCell>
        </TableRow>
      )
    }
  };

  return (
    <Box sx={{ mt: 2, p: 4, border: 1, borderColor: "gray", borderRadius: 5 }}>
    <Grid container sx={{ minWidth: 1200 }}>
      <Grid item xs={6}>
        <Typography
        variant="h4"
        component="h4"
        sx={{ mb: 2, fontWeight: "medium" }}
      >
        {translate("practiceLocation")}
      </Typography>
      <Table sx={{width: "max-content"}}>
      <TableBody>
        {mappedPracticeLocation()}
        <TableRow>
            <TableCell align="left" colSpan={2}>
              <Select
                displayEmpty
                value={newPracticeLocationID || selectedPracticeLocation?.kinshipId || ""}
                onChange={(e) => {
                  console.log("select->newPracticeLocationID =>", e.target.value);
                  setNewPracticeLocationID(e.target.value);
                }}
                sx={{ height: 40 }}
              >
                {PracticeLocationRender}
              </Select>
            </TableCell>
            <TableCell align="left" colSpan={2}>
              <Stack direction="row" spacing={1}>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={(event) => onPracticeLocationAction(event, "SET", newPracticeLocationID)}
                >
                  {translate('set_practice_location')}
                </Button>
              </Stack>
            </TableCell>
        </TableRow>
      </TableBody>
    </Table>
    </Grid>
  </Grid>
</Box>
);
}
